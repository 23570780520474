import { Button } from '@/components/button';
import { Form } from '@/components/form/Form';
import { TextField } from '@/components/form/TextInput';
import { Label } from '@/components/Label';
import {
  Exact,
  UpdateEmployee,
  UpdateSelfMutation,
  useGetMeQuery,
  useUpdateSelfMutation
} from '@/services/gql/graphql.generated';
import { strings } from '@/services/translation/strings';
import { phone } from 'phone';
import { FC, useRef, useState, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { AiOutlineUser } from 'react-icons/ai';
import { FiCheck, FiX } from 'react-icons/fi';
import { twMerge } from 'tailwind-merge';
import { OperationResult } from 'urql';
import { ESSPageHeader } from '../layout/ess/ESSPageHeader';
import { isEmail } from '@/helpers/emailHelper';

export const generalPhoneRegex = /^[+0][0-9]{10,}$/;
export const countryCodeUK = '+44';

export const formatPhoneNumber = (number?: string) => {
  let numberToSend = number?.replaceAll(' ', '');

  if (numberToSend && numberToSend[0] === '0') {
    const arr = numberToSend.split('');
    arr.shift();
    arr.unshift(countryCodeUK);
    numberToSend = arr.join('');
  } else if (numberToSend && !numberToSend.startsWith(countryCodeUK) && numberToSend[0] !== '+') {
    numberToSend = '+' + numberToSend;
  }

  return numberToSend?.substring(0, 16);
};

export interface EditMyProfileFields {
  firstName: string;
  lastName: string;
  role: string;
  email?: string;
  phoneNumber?: string;
}

export const EssMyProfile: FC = () => {
  const [{ data }] = useGetMeQuery();
  const [{ fetching: fetchingStaff }, UpdateEmployee] = useUpdateSelfMutation();
  const fetching = fetchingStaff;
  const handleError = useErrorHandler();

  const [selectedNotification, setSelectedNotification] = useState('email');
  const [isEditing, setIsEditing] = useState(false);

  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    const formElement = formRef.current;

    if (formElement) {
      const keydownHandler = (e: KeyboardEvent) => {
        if (e.key === 'Enter' && (!isPhoneValid || !isEmailValid)) {
          e.preventDefault();
        }
      };

      formElement.addEventListener('keydown', keydownHandler);

      return () => {
        formElement.removeEventListener('keydown', keydownHandler);
      };
    }
  }, [formRef, isPhoneValid, isEmailValid]);

  const validatePhone = (phoneString: string) => {
    if (!phoneString) {
      setIsPhoneValid(true);
      return true;
    }

    const validPhone = phone(phoneString, { country: 'GB' }).isValid;
    setIsPhoneValid(validPhone);
    return validPhone;
  };

  const validateEmail = (email: string) => {
    if (!email) {
      setIsEmailValid(true);
      return true;
    } //If the email is undefined then it is valid - kind of a quirk with the add user forms

    const validEmail = isEmail(email);
    setIsEmailValid(validEmail);
    return validEmail;
  };

  let employeePhone = data?.myEmployee?.phoneNumber !== null ? data?.myEmployee?.phoneNumber : undefined;
  let employeeEmail = data?.myEmployee?.email !== null ? data?.myEmployee?.email : undefined;

  if (!data?.myEmployee) {
    throw Error('Could not get existing employee data');
  }

  const methods = useForm<EditMyProfileFields>({
    defaultValues: {
      firstName: data.myEmployee.firstName,
      lastName: data.myEmployee.lastName,
      role: data.myEmployee.role,
      email: data.myEmployee.email,
      phoneNumber: data.myEmployee.phoneNumber
    }
  });

  const onSubmit = async () => {
    const editMyProfileFieldsData = methods.getValues();

    if (!(editMyProfileFieldsData.phoneNumber || editMyProfileFieldsData.email)) {
      return;
    }

    if (!data?.myEmployee) {
      throw Error('Could not get existing employee data');
    }

    try {
      const updateData = {
        firstName: editMyProfileFieldsData.firstName,
        lastName: editMyProfileFieldsData.lastName,
        role: editMyProfileFieldsData.role,
        email: editMyProfileFieldsData.email ? editMyProfileFieldsData.email : undefined, // Don't send an empty
        identityId: data.myEmployee.identityId,
        employeeId: data.myEmployee.employeeId,
        phoneNumber: editMyProfileFieldsData.phoneNumber ? editMyProfileFieldsData.phoneNumber : undefined,
        ignoreHourContribution: data.myEmployee.ignoreHourContribution,
        isRosterable: data.myEmployee.isRosterable,
        hourlyRate: data.myEmployee.hourlyRate
      };

      let result: OperationResult<UpdateSelfMutation, Exact<{ employee: UpdateEmployee }>> | undefined;

      result = await UpdateEmployee({
        employee: updateData
      });

      setIsEditing(isEditing);

      if (result.error) {
        toast(strings.common.error, {
          position: 'top-center',
          className: 'text-sm',
          icon: <FiX className="h-4 w-4" />
        });
      } else {
        toast(strings.common.success, {
          position: 'top-center',
          className: 'text-sm',
          icon: <FiCheck className="h-4 w-4" />
        });
      }
    } catch (ex) {
      handleError(ex);
    }
  };

  const onSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedNotification(event.target.value);
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  return (
    <>
      <ESSPageHeader title={strings.ess.myProfile.title}>
        {isEditing && (
          <Button
            disabled={(employeePhone !== undefined && !isPhoneValid) || (employeeEmail !== undefined && !isEmailValid)}
            variant={'submit'}
            loading={fetching}
            size="sm"
            onClick={onSubmit}
            className="w-24"
          >
            {strings.common.save}
          </Button>
        )}
      </ESSPageHeader>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center gap-2">
            <div className="w-16 h-16 bg-blue-300 rounded-full flex justify-center items-center text-white text-xl">
              <AiOutlineUser className="w-10 h-10" />
            </div>
            <span>
              {strings.ess.myProfile.employeeFullName(data!.myEmployee!.lastName, data!.myEmployee!.firstName)}
            </span>
            <span>{data?.myEmployee?.employeeId}</span>
          </div>
        </div>
        <Form methods={methods} onSubmit={() => {}}>
          <div className="flex justify-between py-2 px-5">
            <div className="flex flex-col items-left justify-center gap-2 w-full">
              <div className="space-y-4">
                <TextField
                  id="email"
                  name="email"
                  label={strings.ess.myProfile.email}
                  clean={false}
                  value={employeeEmail}
                  readOnly={!isEditing}
                  validator={(email: string) => {
                    const validEmail = validateEmail(email);

                    return validEmail;
                  }}
                  onChange={value => {
                    methods.trigger('email');
                    //@ts-ignore
                    methods.setValue('email', value);
                  }}
                  customValidatorMessage={strings.common.enterValidEmail}
                />
                <TextField
                  id="phoneNumber"
                  name="phoneNumber"
                  label={strings.ess.myProfile.phone}
                  clean={false}
                  value={employeePhone}
                  readOnly={!isEditing}
                  onlyDigits
                  validator={(phone: string) => {
                    const validPhone = validatePhone(phone);

                    return validPhone;
                  }}
                  onChange={value => {
                    methods.trigger('phoneNumber');
                    //@ts-ignore
                    methods.setValue('phoneNumber', formatPhoneNumber(value));
                  }}
                  customValidatorMessage={
                    employeePhone !== undefined && !isPhoneValid ? strings.common.enterValidPhone : undefined
                  }
                />
                <div className="flex flex-col gap-2">
                  <Label className={twMerge('')}>{strings.ess.myProfile.notification}</Label>
                  <div className="grid grid-cols-2 gap-4 pl-2">
                    <div className="grid grid-rows-2">
                      <div className="row-span-1 flex items-center gap-2">
                        <input
                          className="text-blue-900 leading-none"
                          type="radio"
                          name="notification"
                          value="email"
                          checked={selectedNotification === 'email'}
                          onChange={onSelect}
                        />
                        <span className="font-medium text-base">{strings.common.email}</span>
                      </div>
                    </div>
                    <div className="grid grid-rows-2">
                      <div className="row-span-1 flex items-center gap-2">
                        <input
                          className="text-blue-900 leading-none"
                          type="radio"
                          name="notification"
                          value="sms"
                          checked={selectedNotification === 'sms'}
                          onChange={onSelect}
                          disabled
                        />
                        <span className={`font-medium text-base ${selectedNotification !== 'sms' && 'text-gray-400'}`}>
                          {strings.ess.myProfile.sms}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col items-center justify-center">
              <div>
                {isEditing ? (
                  <Button variant={'destructive'} size="sm" onClick={() => window.location.reload()} className="w-24">
                    {strings.common.cancel}
                  </Button>
                ) : (
                  <Button variant={'primary'} onClick={toggleEdit} size="sm" className="w-24">
                    {strings.common.edit}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};
