import { FC, useEffect, useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';

interface Props {
  onRefresh: () => void,
  fetching?: boolean
}

export const RefreshButton: FC<Props> = ({
  onRefresh,
  fetching
}) => {
  const [animate, setAnimate] = useState<boolean>(false);

  useEffect(() => {
    if (fetching) {
      setAnimate(true);
    } else {
      setTimeout(() => setAnimate(false), 700);
    }
  }, [fetching]);
  
  return (
    <button onClick={onRefresh}>
    <FiRefreshCw className={animate ? 'animate-spin' : undefined}/>
  </button>
  );
};
