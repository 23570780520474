import {
  convertDateToGQLFormat,
  dateToApiFormat,
  getCalendarEndDate,
  getCalendarStartDate
} from '@/helpers/dateHelper';
import { NotificationTriggerType } from "@/pages/summary/notifyVacant/useGetUnifiedNotification";
import {
  Shift,
  useGetVacantShiftsCountQuery,
  useGetVacantShiftsForLocationsQuery
} from '@/services/gql/graphql.generated';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMyLocation } from '../useMyLocation';
import {useRosterChangeMonitor} from "@/services/ess/useRosterChangeMonitor";
import {useNotificationChangeMonitor} from "@/services/ess/useNotificationChangeMonitor";
import {useDebouncedCallback} from "use-debounce";

export const useVacantShifts = (viewing: Date) => {
  const requestedStartMonthDate: number = useSystemSettings(state => state.settings.monthStartDay);
  const startCalendarDate = getCalendarStartDate(viewing, requestedStartMonthDate);
  const endCalendarDate = getCalendarEndDate(startCalendarDate, requestedStartMonthDate);
  const location = useMyLocation();

  if (!location || location?.id === undefined) {
    throw Error('No location assigned');
  }

  const range = {
    start: convertDateToGQLFormat(startCalendarDate),
    end: convertDateToGQLFormat(endCalendarDate)
  };

  const [{ data }, refetchShifts] = useGetVacantShiftsForLocationsQuery({
    variables: {
      locationId: location.id,
      triggerType: NotificationTriggerType.VACANT_SHIFT,
      startDate: range.start,
      endDate: range.end
    },
    requestPolicy: 'network-only'
  });

  if (!data?.notifiableVacantShiftsInDateRange?.shifts) {
    throw Error('Unable to get shifts');
  }

  const [fetching, setFetching] = useState<boolean>(false);
  const refetch = useCallback(async () => {
    setFetching(true);
    await refetchShifts({ requestPolicy: 'network-only' });
    setFetching(false);
  }, [refetchShifts]);

  const shifts = useMemo(() => {
    const shifts = data!.notifiableVacantShiftsInDateRange!.shifts!.filter(s => {
      const today = dateToApiFormat(new Date());
      const shiftDate = dateToApiFormat(new Date(s.date));

      return shiftDate >= today;
    }) as Shift[];

    shifts.forEach(s => (s.location = location));

    return shifts;
  }, [data]);

  if (!shifts) {
    throw Error('Could not find shifts list');
  }

  return { shifts: shifts as Shift[], refetch, fetching };
};

export const useVacantShiftsCount = () => {
  const location = useMyLocation();

  if (!location || location?.id === undefined) {
    throw Error('No location assigned');
  }

  const startDate = new Date();

  const [{ data }, refetch] = useGetVacantShiftsCountQuery({
    variables: {
      locationId: location.id,
      startDate: dateToApiFormat(startDate),
      triggerType: NotificationTriggerType.VACANT_SHIFT
    },
    requestPolicy: 'network-only'
  });

  const count = data?.notifiableVacantShiftsInDateRange?.count;

  if (count === undefined) {
    throw Error('Could not get vacant shift count');
  }

  const [refetchRequired, setRefetchRequired] = useState<boolean>(false);

  useRosterChangeMonitor(location.id, startDate, () => setRefetchRequired(true));
  useNotificationChangeMonitor(location.id, startDate, () => setRefetchRequired(true));

  // there could be tens of change events raised at once. debounce the refetch calls.
  const debouncedRefetch = useDebouncedCallback(() => {
    refetch();
    setRefetchRequired(false);
  }, 1000);

  useEffect(() => {
    if (refetchRequired) {
      debouncedRefetch();
    }
  }, [refetchRequired, debouncedRefetch]);

  return count;
};