import { dateToApiFormat } from '@/helpers/dateHelper';
import { useUser } from '@/services/authentication/hooks/user';
import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import {
  UpdateAvailabilityExceptions,
  useUpdateAvailabilityExceptionsMutation
} from '@/services/gql/graphql.generated';
import { hoursToSeconds } from 'date-fns';
import { useCallback } from 'react';
import { EmployeeSchedule } from './useEmployeeSchedule';

export const useSaveEmployeeSchedule = (startDate: Date, endDate: Date) => {
  const [{ fetching }, updateExceptions] = useUpdateAvailabilityExceptionsMutation();

  const user = useUser();

  const employeeId = user.id;

  if (!employeeId) {
    throw Error("Could not get current user's identity ID");
  }

  const { unregisterConfirmation } = useConfirm(state => ({ unregisterConfirmation: state.unregisterForConfirmation }));

  const save = useCallback(
    async (schedule: EmployeeSchedule) => {
      const keys = Object.keys(schedule);

      const exceptions: UpdateAvailabilityExceptions[] = [];
      keys.forEach(key => {
        const date = new Date(key);

        if (dateToApiFormat(date) < dateToApiFormat(startDate) || dateToApiFormat(date) > dateToApiFormat(endDate)) {
          return;
        }

        const scheduleDay = schedule[key];
        const leaveType = scheduleDay.leaveType;
        scheduleDay.availableTimes.forEach(time => {
          const start = time.start || 0;
          const end = time.end || hoursToSeconds(24);
          const timesMatchPattern = time.start === scheduleDay.patternStart && time.end === scheduleDay.patternEnd;

          // We don't actually want to save fixed days off into the database- these are determined by the working
          // pattern instead.
          if ((leaveType && leaveType !== FixedLeaveTypeId.ROSTER) || (!leaveType && !timesMatchPattern)) {
            exceptions.push({
              date: dateToApiFormat(date),
              start,
              end,
              employeeId,
              available: !leaveType,
              leaveTypeId: leaveType
            });
          }
        });
      });

      const result = await updateExceptions({
        exceptions,
        start: dateToApiFormat(startDate),
        end: dateToApiFormat(endDate)
      });

      unregisterConfirmation();

      return result;
    },
    [updateExceptions, startDate, endDate]
  );

  return { save, fetching };
};
