import { useEssAvailabilityTypes } from '@/services/availability/useAvailabilityTypes';
import { FC } from 'react';

interface EssReadAvailabilityProps {
  leaveTypeId?: string;
  transparencyOptions?: {
    alpha?: number;
    shouldUseTransparency?: boolean;
  };
}

export const EssReadAvailability: FC<EssReadAvailabilityProps> = ({ leaveTypeId, transparencyOptions }) => {
  const availabilityTypes = useEssAvailabilityTypes();

  const availType = availabilityTypes.find(type => type.leaveTypeId === leaveTypeId);

  return (
    <span
      className="flex rounded-lg w-full p-3 h-6 gap-1 items-center"
      style={{
        border: 'none',
        color: transparencyOptions?.shouldUseTransparency
          ? 'text-calendar-inactive-default'
          : 'text-calendar-active-default',
        backgroundColor: availType?.color
      }}
    >
      <span className="text-sm">{availType?.label}</span>
    </span>
  );
};
