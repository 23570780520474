import { useMyLocation } from '../useMyLocation';
import { LocationSettings, defaultLocationSettings } from './systemSettings.types';

export const useMyLocationSettings = () => {
  const location = useMyLocation();

  let settings: LocationSettings = { ...defaultLocationSettings };

  if (location?.properties) {
    const properties = JSON.parse(location.properties);
    settings = { ...settings, ...properties };
  }

  return settings;
};
