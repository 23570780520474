import { Button } from '@/components/button';
import { Modal, ModalProps } from '@/components/modal/Modal';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { AvailabilityError, AvailabilityErrorLevel, EssAvailabilityError } from './EssAvailabilityError';

interface Props extends ModalProps {
  onSave: () => Promise<void>;
  errors: AvailabilityError[];
  fetching?: boolean;
}

export const EssAvailabilityWarningModal: FC<Props> = ({ onClose, open, onSave, errors, fetching }) => {
  const hasSeriousError = !!errors.find(e => e.level === AvailabilityErrorLevel.ERROR);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="space-y-4">
        <div className="text-lg font-semibold">
          {hasSeriousError
            ? strings.ess.availability.warningModal.titleErrors
            : strings.ess.availability.warningModal.titleWarnings}
        </div>
        <div className="space-y-2">
          {errors.map((e, index) => (
            <EssAvailabilityError key={`modalErrors-${index}`} error={e} />
          ))}
        </div>
        {hasSeriousError ? (
          <div>
            <Button onClick={onClose}>{strings.common.close}</Button>
          </div>
        ) : (
          <div className="flex justify-between">
            <Button onClick={onClose}>{strings.common.cancel}</Button>
            <Button onClick={onSave} loading={fetching} variant={'primary'}>
              {strings.common.save}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
