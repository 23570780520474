import { EmployeeSchedule } from './useEmployeeSchedule';
import { useValidateScheduleAvailabilityDuration } from './useValidateScheduleAvailabilityDuration';
import { useValidateScheduleLeave } from './useValidateScheduleLeave';

export const useValidateAvailabilities = (days: Date[], schedule: EmployeeSchedule) => {
  const leaveErrors = useValidateScheduleLeave(days, schedule);
  const durationErrors = useValidateScheduleAvailabilityDuration(schedule, days[0], days[days.length - 1]);

  return [...leaveErrors, ...durationErrors];
};
