import React from 'react';
import ReactDOM from 'react-dom/client';
import { Outlet, Route } from 'react-router-dom';
import App from './App';
import './index.css';
import { EssAvailability } from './pages/ess/EssAvailability';
import { EssHome } from './pages/ess/EssHome';
import { EssMyProfile } from './pages/ess/EssMyProfile';
import { EssRosterSchedule } from './pages/ess/EssRosterSchedule';
import { EssVacantPage } from './pages/ess/vacant/EssVacantPage';
import { EssPageContainer } from './pages/layout/ess/ESSPageContainer';
import EssPushNotificationHub from './services/notifications/pushNotifications/EssPushNotificationHub';
import { LanguageProvider } from './services/settings/LanguageProvider';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App>
      <Route
        element={
          <EssPushNotificationHub>
            <LanguageProvider>
              <EssPageContainer>
                <Outlet></Outlet>
              </EssPageContainer>
            </LanguageProvider>
          </EssPushNotificationHub>
        }
      >
        <Route path="/" element={<EssHome />} />
        <Route path="/availability" element={<EssAvailability />} />
        <Route path="/monthlyShift" element={<EssRosterSchedule />} />
        <Route path="/vacant" element={<EssVacantPage />} />
        <Route path="/myProfile" element={<EssMyProfile />} />
      </Route>
    </App>
  </React.StrictMode>
);
