import { RefreshButton } from '@/components/buttons/RefreshButton';
import { useVacantShifts } from '@/services/ess/useVacantShifts';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { useLilius } from 'use-lilius';
import { ESSPageHeader } from '../../layout/ess/ESSPageHeader';
import { EssVacantCalendar } from './EssVacantCalendar';

export const EssVacantPage: FC = () => {
  const { viewing, viewNextMonth, viewPreviousMonth } = useLilius({
    viewing: new Date()
  });

  const { shifts, refetch, fetching } = useVacantShifts(viewing);

  return (
    <>
      <ESSPageHeader title={strings.ess.vacantShifts.title}>
        <RefreshButton onRefresh={refetch} fetching={fetching} />
      </ESSPageHeader>
      <EssVacantCalendar
        shifts={shifts}
        viewing={viewing}
        onNextMonth={viewNextMonth}
        onPrevMonth={viewPreviousMonth}
      />
    </>
  );
};
