import { FC } from 'react';
import { Announcement } from '../settings/sections/manageAnnouncements/Announcement';
import { format } from 'date-fns';

interface Props {
  announcement: Announcement;
}

export const EssAnnouncement: FC<Props> = ({ announcement }) => {
  return (
    <div className="bg-background-mid rounded-md py-4 px-2">
      <b>{announcement.title}</b>
      <p>{announcement.text}</p>
      {/*announcement.displayDate && (
        <p className="text-right text-sm text-[#AAAAAA]">{format(new Date(announcement.displayDate), 'yyyy/MM/dd (HH:MM)')}</p>
      )*/}
    </div>
  );
};
