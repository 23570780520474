import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { useWebPushService } from './useWebPushService';

interface EssPushNotificationHubProps {}

const EssPushNotificationHub: FunctionComponent<PropsWithChildren<EssPushNotificationHubProps>> = ({ children }) => {
  const webPushNotificationService = useWebPushService();

  useEffect(() => {
    webPushNotificationService.setupPushNotifications();
  }, []);

  return children;
};

export default EssPushNotificationHub;
