import { useUser } from '@/services/authentication/hooks/user';
import {
  useRegisterDeviceForWebPushNotifications,
  useUnregisterDeviceForWebPushNotifications
} from '@/services/rest/hooks/notifications';
import { useVariable } from '@softwareimaging/backstage';
import { useErrorHandler } from 'react-error-boundary';
import serviceWorkerURL from '../../../../service-worker?worker&url';

export const useWebPushService = () => {
  const serverKey = useVariable('notificationAppServerKey');
  const { id } = useUser();

  const register = useRegisterDeviceForWebPushNotifications();
  const unregister = useUnregisterDeviceForWebPushNotifications();

  const handleError = useErrorHandler();

  const hasPermission = () => {
    return Notification.permission === 'granted';
  };

  const requestPermission = async () => {
    return await Notification.requestPermission();
  };

  const getSubscription = async () => {
    const registration = await navigator.serviceWorker.register(serviceWorkerURL, {
      type: import.meta.env.DEV ? 'module' : 'classic',
      scope: `./`
    });

    console.log(registration);

    return await registration.pushManager.getSubscription();
  };

  const subscribe = async () => {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: serverKey // VAPID PUBLIC KEY
    });
    return subscription;
  };

  const unsubscribe = async () => {
    const subscription = await getSubscription();
    if (subscription) {
      await subscription.unsubscribe();
    }

    unregister.mutateAsync(id);

    return subscription;
  };

  const setupPushNotifications = async () => {
    if (!hasPermission()) {
      await requestPermission();
    }

    let subscription = await getSubscription();
    if (!subscription) {
      subscription = await subscribe();
    }

    const notificationSubscription = subscription.toJSON();

    if (!notificationSubscription.keys) {
      throw new Error('missing subscription keys');
    }

    const payload = {
      installationId: id,
      platform: 'browser',
      webPushChannel: {
        endpoint: notificationSubscription.endpoint!,
        p256dh: notificationSubscription.keys.p256dh,
        auth: notificationSubscription.keys.auth
      }
    };

    try {
      await register.mutateAsync(payload);
    } catch (ex) {
      handleError(ex);
    }
  };

  const clearPushNotifications = async () => {
    await unsubscribe();
    await unregister.mutateAsync(id);
  };

  return {
    setupPushNotifications,
    clearPushNotifications
  };
};
