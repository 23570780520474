import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { useEssAvailabilityTypes } from '@/services/availability/useAvailabilityTypes';
import { FC, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  leaveTypeId?: string;
  onChange: (newLeaveTypeId?: string) => void;
  className?: string;
}

export const EssLeaveTypePicker: FC<Props> = ({ className, leaveTypeId, onChange }) => {
  const availabilityTypes = useEssAvailabilityTypes();
  const getNextLeaveTypeId = useEssNextLeaveTypeId();
  const availType = availabilityTypes.find(type => type.leaveTypeId === leaveTypeId);

  if (!availType) {
    throw Error('Availability type not defined');
  }

  const onClick = useCallback(() => {
    if (leaveTypeId === FixedLeaveTypeId.ROSTER) {
      return;
    }

    const newState = getNextLeaveTypeId(leaveTypeId);

    onChange(newState);
  }, [getNextLeaveTypeId, leaveTypeId, onChange]);

  const label = availType.name;

  return (
    <button
      className={twMerge(
        className,
        'rounded-md px-2 bg-tertiary font-regular flex items-center justify-center py-2 leading-none'
      )}
      style={{
        backgroundColor: availType.color,
        color: leaveTypeId !== FixedLeaveTypeId.ROSTER ? 'white' : 'black'
      }}
      onClick={onClick}
    >
      {label.toUpperCase().substring(0, 4)}
    </button>
  );
};

export const useEssNextLeaveTypeId = () => {
  const availabilityTypes = useEssAvailabilityTypes(true);
  const options = availabilityTypes.filter(type => type.leaveTypeId !== FixedLeaveTypeId.ROSTER);

  return useCallback(
    (leaveTypeId?: string) => {
      const currentIndex = options.findIndex(l => l.leaveTypeId === leaveTypeId);
      if (currentIndex < 0) {
        throw Error('Invalid LeaveTypeId');
      }

      // circular indexing
      return options[(currentIndex + 1) % options.length].leaveTypeId;
    },
    [options]
  );
};
