import Divider from '@/components/Divider';
import { SnpsLogo } from '@/components/decoration/SnpsLogo';
import { ConfirmedLink } from '@/components/link/ConfirmedLink';
import { DropdownAction } from '@/components/menu/Action';
import { Dropdown, DropdownOrigin } from '@/components/menu/Dropdown';
import { copyToClipboard, useGetGqlHeader } from '@/helpers/debugHelper';
import { useSignOut } from '@/services/authentication/hooks/auth';
import { useWebPushService } from '@/services/notifications/pushNotifications/useWebPushService';
import { useLanguageProvider } from '@/services/settings/LanguageProvider';
import { strings } from '@/services/translation/strings';
import { useFlag } from '@softwareimaging/backstage';
import { FiCopy, FiGlobe, FiLogOut, FiSettings } from 'react-icons/fi';
import { MdFace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import LanguageToggle from './LanguageToggle';

const ICON_CLASS = 'h-6 w-6 lg:h-8 lg:w-8';

export const ESSHeader: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = () => {
  const navigate = useNavigate();
  const webPushNotificationService = useWebPushService();

  const { language, setLanguage } = useLanguageProvider(state => ({
    language: state.language,
    setLanguage: state.setLanguage
  }));

  const debug = useFlag('debug');
  const getGqlHeader = useGetGqlHeader();

  const signout = useSignOut();

  const signUserOut = async () => {
    await webPushNotificationService.clearPushNotifications();

    signout();
  };

  return (
    <div className="w-full h-16 border-b flex items-center px-10 gap-10 bg-background-mid">
      <ConfirmedLink to="">
        <SnpsLogo />
      </ConfirmedLink>
      <div id="spaceFiller" className="flex-1" />
      <Dropdown origin={DropdownOrigin.TOP_RIGHT} button={<FiSettings className="h-6 w-6 lg:h-8 lg:w-8" />}>
        <DropdownAction onClick={() => navigate(`/myProfile`)} className="flex items-center gap-2">
          <MdFace className={ICON_CLASS} />
          <span>{strings.layout.headerMenu.myProfile}</span>
        </DropdownAction>
        <DropdownAction onClick={async () => await setLanguage(language === 'en' ? 'ja' : 'en')}>
          <div className="flex flex-col">
            <div className="flex flex-row space-x-4">
              <FiGlobe className={ICON_CLASS} />
              <span>{strings.layout.headerMenu.language}</span>
            </div>
            <div className="flex flex-row justify-center items-center gap-2 p-6">
              <div className="flex flex-row justify-center items-center gap-1">
                <img src="/JapaneseFlag.svg" alt="Japanese" width="25 lg:30" />
                <span className="text-black font-light">{strings.common.jp}</span>
              </div>
              <div>
                <div className="flex items-center justify-center w-full">
                  <LanguageToggle id={'language'} language={language} />
                </div>
              </div>
              <div className="flex flex-row justify-center items-center gap-1">
                <img src="/UKFlag.svg" alt="UK" width="25 lg:30" />
                <span className="text-black font-light">{strings.common.en}</span>
              </div>
            </div>
          </div>
        </DropdownAction>

        {debug && (
          <DropdownAction
            onClick={async () => {
              const header = await getGqlHeader();
              copyToClipboard(header);
            }}
            className="flex items-center gap-2"
          >
            <FiCopy className={ICON_CLASS} />
            <span>Playground Header</span>
          </DropdownAction>
        )}
        <Divider thickness={2} />
        <DropdownAction onClick={signUserOut} className="flex items-center gap-2">
          <FiLogOut className={ICON_CLASS} />
          <span>{strings.layout.headerMenu.logout}</span>
        </DropdownAction>
      </Dropdown>
    </div>
  );
};
