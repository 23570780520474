import { useMemo } from 'react';
import { useMyLocationSettings } from '../settings/useMyLocationSettings';

export const useMyAnnouncements = () => {
  const settings = useMyLocationSettings();

  const announcements = useMemo(() => {
    const today = new Date();

    return settings.announcements.filter(a => {
      if (!a.isActive) {
        return false;
      }

      if (a.showAtDate) {
        if (a.showAtDate > today) {
          return false;
        }
      }

      return true;
    });
  }, [settings]);

  return announcements;
};
