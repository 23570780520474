import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { AVAILABLE_COLOR } from '@/services/availability/useAvailabilityTypes';
import { useEssLeaveTypes } from '@/services/leaveManagement/LeaveTypesProvider';
import { strings } from '@/services/translation/strings';

export interface KeyLeaveType {
  label: string;
  name: string;
  description: string;
  leaveType?: string;
  color: string;
}

export const useAvailabilityKey = () => {
  const leaveTypes = useEssLeaveTypes();

  const rosterLeaveType = leaveTypes.find(lt => lt.id === FixedLeaveTypeId.ROSTER);

  if (!rosterLeaveType) {
    throw Error('Roster leave type should be defined');
  }

  const options: KeyLeaveType[] = [
    {
      label: strings.ess.availability.mode.label.roster,
      name: strings.ess.availability.mode.name.roster,
      description: strings.ess.availability.mode.description.cannotEdit,
      leaveType: FixedLeaveTypeId.ROSTER,
      color: rosterLeaveType.properties.color
    },
    {
      label: strings.ess.availability.mode.name.available,
      name: strings.ess.availability.mode.name.available,
      description: strings.ess.availability.mode.description.default,
      color: AVAILABLE_COLOR
    }
  ];

  leaveTypes
    ?.filter(leaveType => leaveType.id !== FixedLeaveTypeId.ROSTER && leaveType.active)
    .forEach(leaveType => {
      options.push({
        label: leaveType.name,
        name: leaveType.properties.displayName ? leaveType.properties.displayName : leaveType.name,
        description: strings.ess.availability.mode.description.taps(leaveType.properties.tapNumber),
        leaveType: leaveType.id,
        color: leaveType.properties.color
      });
    });

  return options;
};
