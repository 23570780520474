import { Shift } from "@/services/gql/graphql.generated";
import { useMemo } from "react";

export const useLastModified = (shifts: Shift[]) => {
  return useMemo(() => {
    if (!shifts || shifts.length === 0) {
      return null;
    }

    let latestLastModified = shifts[0].lastModified;

    shifts.forEach(shift => {
      const lastModifiedDate = shift.lastModified;

      if (lastModifiedDate > latestLastModified) {
        latestLastModified = lastModifiedDate;
      }
    });

    return new Date(latestLastModified + 'Z');
  }, [shifts]);
}