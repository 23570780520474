import { dateToCalendarStateIndex } from '@/helpers/calendarHelper';
import { dateToApiFormat, getDaysInMonth } from '@/helpers/dateHelper';
import { FixedLeaveTypeId } from '@/services/availability/availability.types';
import { useMapToLeaveTypeId } from '@/services/availability/useAvailabilityTypes';
import { StaffDayTimes } from '@/services/availability/useStaffAvailability';
import { useConfirm } from '@/services/confirm/ConfirmationService';
import {
  AvailabilityException,
  WorkingPatternDay,
  useEmployeeWorkingPatternQuery,
  useMyAvailabilityExceptionsQuery
} from '@/services/gql/graphql.generated';
import { strings } from '@/services/translation/strings';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface EmployeeScheduleDay {
  date: Date;
  leaveType?: string;
  availableTimes: StaffDayTimes[];
  patternStart?: number;
  patternEnd?: number;
}

export type EmployeeSchedule = { [date: string]: EmployeeScheduleDay };

const useBuildSchedule = (selectedDate: Date) => {
  const mapToLeaveTypeId = useMapToLeaveTypeId();
  const dates = useMemo(() => {
    // For contracted days off, we need to load these as the month changes.
    const prev = new Date(selectedDate);
    prev.setMonth(prev.getMonth() - 1);

    const next = new Date(selectedDate);
    next.setDate(1);
    next.setMonth(next.getMonth() + 1);

    const prevMonth = getDaysInMonth(prev);
    const currMonth = getDaysInMonth(selectedDate);
    const nextMonth = getDaysInMonth(next);

    return [...prevMonth, ...currMonth, ...nextMonth];
  }, [selectedDate]);

  return useCallback(
    (patterns: WorkingPatternDay[], exceptions: AvailabilityException[]) => {
      const schedule: EmployeeSchedule = {};

      dates.forEach(d => {
        const dayInSchedule: EmployeeScheduleDay = { date: d, availableTimes: [] };
        schedule[dateToCalendarStateIndex(d)] = dayInSchedule;

        const pattern = patterns?.find(p => p.dayNumber === d.getDay());
        if (!pattern) {
          throw Error('Pattern list is incomplete.');
        }

        dayInSchedule.patternStart = pattern.startTime;
        dayInSchedule.patternEnd = pattern.endTime;

        if (!pattern.available) {
          dayInSchedule.leaveType = FixedLeaveTypeId.ROSTER;
        } else {
          const exceptionList = exceptions.filter(e => dateToApiFormat(new Date(e.date)) === dateToApiFormat(d));

          if (exceptionList.length === 0) {
            dayInSchedule.availableTimes.push({ start: pattern.startTime, end: pattern.endTime });
          } else {
            dayInSchedule.availableTimes = exceptionList.map(e => ({ start: e.start, end: e.end }));
            dayInSchedule.leaveType = mapToLeaveTypeId(exceptionList[0].leaveType?.id);
          }
        }
      });

      return schedule;
    },
    [mapToLeaveTypeId, dates]
  );
};

export const useEmployeeSchedule = (selectedDate: Date) => {
  const [{ data: myAvailabilityData }, refetchExceptions] = useMyAvailabilityExceptionsQuery({
    requestPolicy: 'network-only'
  });
  const exceptions = myAvailabilityData?.myAvailabilityExceptions?.exceptions as AvailabilityException[];

  const [{ data: myWorkingPatternData }, refetchPatterns] = useEmployeeWorkingPatternQuery({
    requestPolicy: 'network-only'
  });

  if (
    !myWorkingPatternData?.employeeWorkingPatterns?.periods ||
    myWorkingPatternData?.employeeWorkingPatterns?.periods.length === 0
  ) {
    throw Error('Employee working pattern data should be setup');
  }

  const patterns = myWorkingPatternData?.employeeWorkingPatterns?.periods[0].patterns as WorkingPatternDay[];
  if (!patterns) {
    throw Error('Patterns should be defined.');
  }

  const [fetching, setFetching] = useState<boolean>(false);
  const refetch = useCallback(async () => {
    setFetching(true);
    await refetchExceptions({ requestPolicy: 'network-only' });
    await refetchPatterns({ requestPolicy: 'network-only' });
    setFetching(false);
  }, [refetchExceptions, refetchPatterns]);

  const buildSchedule = useBuildSchedule(selectedDate);
  
  const [schedule, setSchedule] = useState<EmployeeSchedule>(() => buildSchedule(patterns, exceptions));

  useEffect(() => {
    setSchedule(buildSchedule(patterns, exceptions));
  }, [exceptions, patterns, buildSchedule]);

  const { registerConfirmation } = useConfirm(state => ({ registerConfirmation: state.registerForConfirmation }));

  const setScheduleDay = useCallback(
    (scheduleDay: EmployeeScheduleDay) => {
      const newSchedule = { ...schedule };
      newSchedule[dateToCalendarStateIndex(scheduleDay.date)] = scheduleDay;
      setSchedule(newSchedule);
      registerConfirmation(strings.ess.availability.saveConfirm);
    },
    [schedule, setSchedule]
  );

  return { schedule, setScheduleDay, refetch, fetching };
};
