import Toggle from '@/components/Toggle';
import { FC } from 'react';

interface ToggleProps {
  id: string;
  language?: string;
}

const LanguageToggle: FC<ToggleProps> = ({ id, language }) => {
  const isChecked = language === 'en';

  return <Toggle value={isChecked} onChange={()=>{}} id={id} />;
};

export default LanguageToggle;
