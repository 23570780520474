import { Button } from '@/components/button';
import { useMyAnnouncements } from '@/services/announcements/useMyAnnouncements';
import { useUser } from '@/services/authentication/hooks/user';
import { displayName } from '@/services/translation/name';
import { strings } from '@/services/translation/strings';
import { format } from 'date-fns';
import { FC } from 'react';
import { BsCalendar } from 'react-icons/bs';
import { MdBeachAccess } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { ESSPageHeader } from '../layout/ess/ESSPageHeader';
import { EssAnnouncement } from './EssAnnouncement';
import { useVacantShiftsCount } from '@/services/ess/useVacantShifts';

export const EssHome: FC = () => {
  const user = useUser();
  const today = new Date();
  const navigate = useNavigate();

  const announcements = useMyAnnouncements();
  const shiftsCount = useVacantShiftsCount();

  return (
    <div className="flex flex-col gap-8">
      <ESSPageHeader className="text-base" title={displayName(user)}>
        <div>{`${format(today, 'yyyy/MM/dd')} (${strings.common.daysOfWeekMid[today.getDay()]})`}</div>
      </ESSPageHeader>
      <div className="flex justify-center gap-8">
        <Button
          className="flex flex-col items-center p-0 gap-4 bg-orange-400 h-28 w-28 rounded-md shadow-md hover:bg-orange-300"
          onClick={() => navigate('/availability')}
        >
          <MdBeachAccess className="h-10 w-10 text-orange-900" />
          <span className="text-xs  text-orange-900">{strings.ess.availability.title}</span>
        </Button>
        <Button
          className="flex flex-col items-center p-4 gap-4 bg-green-600 h-28 w-28 rounded-md shadow-md hover:bg-green-500 relative"
          onClick={() => navigate('/vacant')}
        >
          <MdBeachAccess className="h-10 w-10 text-white" />
          <span className="text-xs text-white">{strings.ess.vacantShifts.title}</span>
          {shiftsCount > 0 && (
            <div className="bg-red-500 rounded-full p-1.5 absolute -top-2 -right-2 text-white shadow-md">
              {shiftsCount}
            </div>
          )}
        </Button>
        <Button
          className="flex flex-col items-center p-4 gap-4 bg-blue-500 h-28 w-28 rounded-md shadow-md hover:bg-blue-400"
          onClick={() => navigate('/monthlyShift')}
        >
          <BsCalendar className="h-10 w-10 text-white" />
          <span className="text-xs text-white">{strings.ess.monthlyShift.title}</span>
        </Button>
      </div>
      <div>
        <p className="font-semibold mb-2">{strings.ess.announcements.title}</p>
        <div className="space-y-2">
          {announcements.map(a => (
            <EssAnnouncement key={a.id} announcement={a} />
          ))}
        </div>
      </div>
    </div>
  );
};
