import { getCalendarStartDate, getCalendarEndDate, convertDateToGQLFormat } from '@/helpers/dateHelper';
import { useGetMyShiftsForDateRangeQuery, Shift } from '@/services/gql/graphql.generated';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { useState, useCallback } from 'react';

export const useMyShifts = (viewing: Date) => {
  const requestedStartMonthDate: number = useSystemSettings(state => state.settings.monthStartDay);
  const startCalendarDate = getCalendarStartDate(viewing, requestedStartMonthDate);
  const endCalendarDate = getCalendarEndDate(startCalendarDate, requestedStartMonthDate);

  const range = {
    start: convertDateToGQLFormat(startCalendarDate),
    end: convertDateToGQLFormat(endCalendarDate)
  };

  const [{ data }, refetchShifts] = useGetMyShiftsForDateRangeQuery({
    variables: {
      range: range
    }
  });

  const [fetching, setFetching] = useState<boolean>(false);
  const refetch = useCallback(async () => {
    setFetching(true);
    await refetchShifts({ requestPolicy: 'network-only' });
    setFetching(false);
  }, [refetchShifts]);

  const shifts = data?.myShiftsForDateRange?.shifts;

  if (!shifts) {
    throw Error('Could not find shifts list');
  }

  return { shifts: shifts as Shift[], refetch, fetching };
};
