import { getTranslatedCalendarHeader } from '@/helpers/calendarHelper';
import { useLanguageProvider } from '@/services/settings/LanguageProvider';
import { FC } from 'react';
import { IoChevronBackSharp, IoChevronForwardSharp } from 'react-icons/io5';

interface Props {
  viewPreviousMonth: () => void;
  viewNextMonth: () => void;
  startDate: Date;
  endDate: Date;
  viewing: Date;
  minAllowedDate: Date;
  maxAllowedDate: Date;
  id: string;
}

export const EssCalendarHeader: FC<Props> = ({
  viewPreviousMonth,
  viewNextMonth,
  startDate,
  endDate,
  viewing,
  minAllowedDate,
  maxAllowedDate,
  id
}) => {
  const language = useLanguageProvider(state => state.language);
  const isSameMonth = startDate.getFullYear() === endDate.getFullYear() && startDate.getMonth() === endDate.getMonth();

  return (
    <div id={id} className="flex items-center justify-between border-0 pb-2 border-b w-full mb-2 shadow-md">
      <button className="focus:outline-none" onClick={viewPreviousMonth} disabled={viewing <= minAllowedDate}>
        <IoChevronBackSharp className={`w-6 h-6 ${viewing <= minAllowedDate ? 'text-gray-400' : ''}`} />
      </button>

      <p className="text-primary font-semibold">
        {isSameMonth
          ? getTranslatedCalendarHeader(startDate, language)
          : `${getTranslatedCalendarHeader(startDate, language)} - ${getTranslatedCalendarHeader(endDate, language)}`}
      </p>

      <button className="focus:outline-none" onClick={viewNextMonth} disabled={viewing >= maxAllowedDate}>
        <IoChevronForwardSharp className={`w-6 h-6 ${viewing >= maxAllowedDate ? 'text-gray-400' : ''}`} />
      </button>
    </div>
  );
};
