import { strings } from '@/services/translation/strings';
import { format } from 'date-fns';
import { ja, enUS } from 'date-fns/locale';

export const dateToCalendarStateIndex = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

export const getTranslatedCalendarHeader = (date: Date, language: string) => {
  return format(date, 'MMMM yyyy', { locale: language === 'ja' ? ja : enUS });
};

export const getCalendarTextColor = (isInRange: boolean, isSaturday: boolean, isSunday: boolean): string => {
  let color = '';
  if (isSaturday) {
    color = isInRange ? '#0070d2' : '#0070d280';
  } else if (isSunday) {
    color = isInRange ? '#ff0000' : '#ff000080';
  } else {
    color = isInRange ? '#323232' : '#32323280';
  }

  return `text-[${color}]`;
};

export const daysOfWeekTranslationType = (type: 'full' | 'mid' | 'basic') => {
  switch (type) {
    case 'mid':
      return 'daysOfWeekMid';
    case 'full':
      return 'daysOfWeekFull';
    default:
      return 'daysOfWeek';
  }
};

export const getTranslatedDayOfWeek = (type: 'full' | 'mid' | 'basic', dayIndex: number) => {
  let formatKey = 'daysOfWeek';
  switch (type) {
    case 'mid':
      formatKey = 'daysOfWeekMid';
      break;
    case 'full':
      formatKey = 'daysOfWeekFull';
      break;
  }

  return (strings.common as any)[formatKey][dayIndex];
};
