import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends PropsWithChildren {
  title: string;
  className?: string;
}

export const ESSPageHeader: FC<Props> = ({ title, className, children }) => {
  return (
    <>
      <div className={twMerge('flex justify-between py-2 px-5 text-lg items-center', className)}>
        <p className="text-blue-900 text-lg font-semibold">{title}</p>
        {children && children}
      </div>
    </>
  );
};
