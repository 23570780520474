import { useAPIMutation } from '../APIProvider';
import { WebPushRegistration } from '../contracts/notifications';
import { APIError } from '../errors/APIError';

export const useRegisterDeviceForWebPushNotifications = () => {
  return useAPIMutation(client => {
    return async (payload: WebPushRegistration) => {
      const response = await client.notifications.register({
        body: payload
      });

      if (response.status !== 200) throw APIError.fromResponse(response);

      return response.body;
    };
  });
};

export const useUnregisterDeviceForWebPushNotifications = () => {
  return useAPIMutation(client => {
    return async (id: string) => {
      const response = await client.notifications.unregister({ params: { id } });

      if (response.status !== 200) throw APIError.fromResponse(response);

      return response.body;
    };
  });
};
