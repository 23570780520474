import { RefreshButton } from '@/components/buttons/RefreshButton';
import { EssMonthlyShift } from '@/components/monthlyShift/EssMonthlyShift';
import { useMyShifts } from '@/services/ess/useMyShifts';
import { strings } from '@/services/translation/strings';
import { FC } from 'react';
import { useLilius } from 'use-lilius';
import { ESSPageHeader } from '../layout/ess/ESSPageHeader';
import { useEmployeeSchedule } from './useEmployeeSchedule';

export const EssRosterSchedule: FC = () => {
  const { viewing, viewNextMonth, viewPreviousMonth } = useLilius({
    viewing: new Date()
  });

  const { schedule, refetch: refetchSchedule, fetching: fetchingSchedule } = useEmployeeSchedule(viewing);
  const { shifts, refetch: refetchShifts, fetching: fetchingShifts } = useMyShifts(viewing);

  const fetching = fetchingSchedule || fetchingShifts;
  const refetch = () => {
    refetchSchedule();
    refetchShifts();
  }
  
  return (
    <>
      <ESSPageHeader title={strings.ess.monthlyShift.monthlySchedule}>
        <RefreshButton onRefresh={refetch} fetching={fetching} />
      </ESSPageHeader>
      <div className="flex flex-col">
        <EssMonthlyShift
          shifts={shifts}
          schedule={schedule}
          viewing={viewing}
          onNextMonth={viewNextMonth}
          onPrevMonth={viewPreviousMonth}
        />
      </div>
    </>
  );
};
