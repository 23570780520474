import { FC, PropsWithChildren, Suspense } from 'react';
import { ESSHeader } from './ESSHeader';

import { LoadingIndicator } from '@/components/LoadingIndicator';

export const EssPageContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="h-screen flex flex-col">
      <ESSHeader />      
      <div className="flex-1 overflow-hidden">
        <Suspense fallback={<LoadingIndicator />}>
          <div className="max-w-xl mx-auto">{children}</div>
        </Suspense>
      </div>
    </div>
  );
};
