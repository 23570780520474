import gql from "graphql-tag";
import {useSubscription} from "urql";
import {useCallback} from "react";

const ROSTER_CHANGE_SUBSCRIPTION = gql`
    subscription RosterChangeSubscription {
        rosterChange {
            locationId
            rosterId
            date
        }
    }
`;

export const useRosterChangeMonitor = (locationId: number, startDate: Date, callback: ()=>void) => {
  const handleSubscription = useCallback((previous: any, response: any) => {
    if (!response.rosterChange) return;
    const date = new Date(response.rosterChange.date);
    date.setHours(0, 0, 0, 0);
    if (date >= startDate && locationId === response.rosterChange.locationId) {
      callback();
    }
  }, [locationId, startDate, callback]);

  useSubscription({
      query: ROSTER_CHANGE_SUBSCRIPTION,
    },
    handleSubscription);
};