import { FC } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

export enum AvailabilityErrorLevel {
  WARNING,
  ERROR
}

export interface AvailabilityError {
  level?: AvailabilityErrorLevel;
  text: string;
}

interface Props {
  error: AvailabilityError;
}

export const EssAvailabilityError: FC<Props> = ({ error }) => {
  return (
    <div className="flex flex-row items-center space-x-4">
      <FiAlertTriangle
        className={error.level === AvailabilityErrorLevel.ERROR ? 'text-red-500 h-6 w-6' : 'text-orange-500 h-6 w-6'}
      />
      <span>{error.text}</span>
    </div>
  );
};
