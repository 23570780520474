import {
  dateToApiFormat,
  getCalendarEndDate,
  getCalendarStartDate
} from '@/helpers/dateHelper';
import { Shift } from '@/services/gql/graphql.generated';
import { useSystemSettings } from '@/services/settings/SystemSettingsProvider';
import { strings } from '@/services/translation/strings';
import { addMonths, subMonths } from 'date-fns';
import { FC, useEffect, useRef } from 'react';
import { EssCalendarHeader } from '@/components/calendar/EssCalendarHeader';
import { EssVacantShift } from './EssVacantShift';

interface Props {
  shifts: Shift[];
  onNextMonth: () => void;
  onPrevMonth: () => void;
  viewing: Date;
}

export const EssVacantCalendar: FC<Props> = ({ onNextMonth, onPrevMonth, viewing, shifts }) => {
  const requestedStartMonthDate: number = useSystemSettings(state => state.settings.monthStartDay);
  const startCalendarDate = getCalendarStartDate(viewing, requestedStartMonthDate);
  const endCalendarDate = getCalendarEndDate(startCalendarDate, requestedStartMonthDate);

  const nextDayRef = useRef(null);
  const calendarRef = useRef(null);

  useEffect(() => {
    if (nextDayRef.current) {
      //@ts-ignore
      nextDayRef.current?.scrollIntoView();
    } else {
      //@ts-ignore
      calendarRef.current.scrollTo(0, 0);
    }
  }, [nextDayRef, calendarRef, viewing]);

  const today = new Date();
  const minAllowedDate = subMonths(today, 0);
  const maxAllowedDate = addMonths(today, 4);

  const nextShiftIndex = shifts.findIndex(s => dateToApiFormat(new Date(s.date)) >= dateToApiFormat(today));

  return (
    <div className="w-full">
      <div className="relative">
        <div className="absolute top-0 left-0 w-full mt-2 py-2 border border-x-0 border-gray-300 bg-white shadow-md">
          <EssCalendarHeader
            id={'monthlyShift'}
            viewPreviousMonth={onPrevMonth}
            viewNextMonth={onNextMonth}
            startDate={startCalendarDate}
            endDate={endCalendarDate}
            viewing={viewing}
            minAllowedDate={minAllowedDate}
            maxAllowedDate={maxAllowedDate}
          />
          <div className="flex flex-col overflow-y-auto max-h-[65vh] shifts-publishing" ref={calendarRef}>
            {shifts.length === 0 && (
              <p className="flex justify-end text-sm pr-2 pb-2">{strings.ess.monthlyShift.noShifts}</p>
            )}
            {shifts.map((shift, index) => (
              <div
                key={`${shift.date}-${shift.start}-${shift.end}-${index}`}
                ref={index === nextShiftIndex ? nextDayRef : undefined}
              >
                <EssVacantShift shift={shift} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
